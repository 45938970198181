export const i18n = {
  "de-CH": {
    page: {
      headline: "Neues Passwort",
      greetingText: "Bitte geben Sie Ihr neues Passwort ein.",
      newPasswordText: "Neues Passwort",
      confirmPasswordText: "Neues Passwort bestätigen",
      buttonLabel: "Passwort bestätigen",
      customerServiceText: "Kundenservice",
      customerServiceLink: "https://service.fielmann.ch/hc/de-ch/requests/new/",
      impressumText: "Impressum",
      impressumLink: "https://www.fielmann.ch/impressum/",
      privacyPolicyText: "Datenschutz",
      privacyPolicyLink: "https://www.fielmann.ch/datenschutz/",
      privacyPolicySettingsText: "Datenschutz-Einstellungen",
      privacyPolicySettingsLink: "javascript:;",
    },
    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError:
        "Bitte vergewissere dich, dass das Passwort und die Bestätigung übereinstimmen.",
      passwordStrength: {
        containsAtLeast:
          "Enthält mindestens %d der folgenden %d Arten von Zeichen:",
        identicalChars:
          'Nicht mehr als %d identische Zeichen hintereinander (wie "%s" nicht erlaubt)',
        nonEmpty: "Ein nicht leeres Passwort ist erforderlich",
        numbers: "Zahlen (wie 0-9)",
        lengthAtLeast: "Mindestens %d Zeichen lang",
        lowerCase: "Kleinbuchstaben (a-z)",
        shouldContain: "Sollte enthalten:",
        specialCharacters: "Sonderzeichen (wie !@#$%^&*)",
        upperCase: "Großbuchstaben (A-Z)",
      },
      successMessage: "Dein Passwort wurde erfolgreich zurückgesetzt.",
      configurationError:
        "Ein Fehler ist aufgetreten. Es scheint eine Fehlkonfiguration im Formular vorzuliegen.",
      networkError:
        "Der Server kann nicht erreicht werden; es gibt ein Problem mit dem Netzwerk.",
      timeoutError:
        "Der Server kann nicht erreicht werden; bitte versuche es erneut.",
      serverError:
        "Es gab einen Fehler beim Verarbeiten der Passwortzurücksetzung.",
      headerText: "Gib ein neues Passwort für<br />{email} ein",
      title: "Passwort ändern",
      weakPasswordError: "Das Passwort ist zu schwach.",
      passwordHistoryError: "Das Passwort wurde zuvor verwendet.",
    },
  },
  "it-CH": {
    page: {
      headline: "Nuova password",
      greetingText: "Inserisci la tua nuova password.",
      newPasswordText: "Nuova password",
      confirmPasswordText: "Conferma la nuova password",
      buttonLabel: "Conferma password",
      customerServiceText: "Contatti",
      customerServiceLink: "https://service.fielmann.ch/hc/it-ch/requests/new/",
      impressumText: "Dati societari",
      impressumLink: "https://it.fielmann.ch/datisocietari/",
      privacyPolicyText: "Tutela dei dati",
      privacyPolicyLink: "https://it.fielmann.ch/protezionedeidati/",
      privacyPolicySettingsText: "Impostazioni privacy",
      privacyPolicySettingsLink: "javascript:;",
    },
    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError:
        "Assicurati che la password e la conferma siano uguali.",
      passwordStrength: {
        containsAtLeast:
          "Contiene almeno %d dei seguenti %d tipi di caratteri:",
        identicalChars:
          'Non più di %d caratteri identici di fila (come "%s" non consentito)',
        nonEmpty: "È richiesta una password non vuota",
        numbers: "Numeri (come 0-9)",
        lengthAtLeast: "Almeno %d caratteri di lunghezza",
        lowerCase: "Lettere minuscole (a-z)",
        shouldContain: "Dovrebbe contenere:",
        specialCharacters: "Caratteri speciali (come !@#$%^&*)",
        upperCase: "Lettere maiuscole (A-Z)",
      },
      successMessage: "La tua password è stata reimpostata con successo.",
      configurationError:
        "Si è verificato un errore. Sembra esserci una cattiva configurazione nel modulo.",
      networkError:
        "Il server non può essere raggiunto; c'è un problema con la rete.",
      timeoutError: "Il server non può essere raggiunto; per favore riprova.",
      serverError:
        "Si è verificato un errore durante l'elaborazione del ripristino della password.",
      headerText: "Inserisci una nuova password per<br />{email}",
      title: "Cambia password",
      weakPasswordError: "La password è troppo debole.",
      passwordHistoryError: "La password è stata utilizzata in precedenza.",
    },
  },
  "fr-CH": {
    page: {
      headline: "Nouveau mot de passe",
      greetingText: "Veuillez entrer votre nouveau mot de passe.",
      newPasswordText: "Nouveau mot de passe",
      confirmPasswordText: "Confirmer le nouveau mot de passe",
      buttonLabel: "Confirmer le mot de passe",
      customerServiceText: "Service clièntele",
      customerServiceLink: "https://service.fielmann.ch/hc/fr-ch/requests/new/",
      impressumText: "Mentions légales",
      impressumLink: "https://fr.fielmann.ch/mentions-legales/",
      privacyPolicyText: "Protection des données",
      privacyPolicyLink: "https://fr.fielmann.ch/protectiondesdonnees/",
      privacyPolicySettingsText: "Paramètres de confidentialité",
      privacyPolicySettingsLink: "javascript:;",
    },

    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError:
        "Veuillez vous assurer que le mot de passe et la confirmation sont identiques.",
      passwordStrength: {
        containsAtLeast:
          "Contient au moins %d des %d types de caractères suivants :",
        identicalChars:
          "Pas plus de %d caractères identiques d'affilée (comme \"%s\" n'est pas autorisé)",
        nonEmpty: "Un mot de passe non vide est requis",
        numbers: "Chiffres (comme 0-9)",
        lengthAtLeast: "Au moins %d caractères de long",
        lowerCase: "Lettres minuscules (a-z)",
        shouldContain: "Devrait contenir :",
        specialCharacters: "Caractères spéciaux (comme !@#$%^&*)",
        upperCase: "Lettres majuscules (A-Z)",
      },
      successMessage: "Votre mot de passe a été réinitialisé avec succès.",
      configurationError:
        "Une erreur s'est produite. Il semble y avoir une mauvaise configuration dans le formulaire.",
      networkError:
        "Le serveur est inaccessible; il y a un problème avec le réseau.",
      timeoutError: "Le serveur est inaccessible; veuillez réessayer.",
      serverError:
        "Une erreur s'est produite lors du traitement de la réinitialisation du mot de passe.",
      headerText: "Entrez un nouveau mot de passe pour<br />{email}",
      title: "Changer le mot de passe",
      weakPasswordError: "Le mot de passe est trop faible.",
      passwordHistoryError: "Le mot de passe a déjà été utilisé.",
    },
  },
};

export function getLang() {
  const hash = window.location.hash;
  const params = hash.substring(1);
  const urlParams = new URLSearchParams(params);

  return urlParams.get("lang") || "de-CH";
}

export function getAuth0Dict() {
  return i18n[getLang()].auth0;
}

export function getPageDict() {
  return i18n[getLang()].page;
}
